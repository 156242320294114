.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-link {
  display: contents;
}
.home-logo {
  width: 125px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.home-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.home-text {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link1 {
  display: none;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link2 {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-right: var(--dl-space-space-tripleunit);
  text-transform: capitalize;
  text-decoration: none;
}
.home-link3 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link4 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image01 {
  width: 50px;
  object-fit: cover;
}
.home-text03 {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
}
.home-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon2 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.home-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text06 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn2 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-image02 {
  width: 50%;
  object-fit: contain;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text14 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image03 {
  width: 136px;
  height: 147px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text17 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image04 {
  width: 128px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text20 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image05 {
  width: 136px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text21 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-screenshots {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text22 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-service-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image06 {
  width: 90%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text25 {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image07 {
  width: 90%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text26 {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image08 {
  width: 90%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text27 {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card3 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content3 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image09 {
  width: 90%;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text30 {
  color: var(--dl-color-gray-500);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-about {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-heading-container2 {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text31 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-secondary-text {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn3 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-cta-btn4 {
  color: var(--dl-color-gray-white);
  width: 80%;
  display: none;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .home-cta-btn2 {
    width: 40%;
  }
  .home-image02 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card {
    align-self: center;
    margin-bottom: 110px;
  }
  .home-about {
    flex-direction: column;
  }
  .home-heading-container2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-cta-btn3 {
    width: 40%;
    display: none;
  }
  .home-cta-btn4 {
    width: 320px;
    display: block;
    margin-top: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-right: 91px;
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-logo {
    width: 120px;
  }
  .home-links-container {
    display: none;
  }
  .home-heading {
    text-align: center;
  }
  .home-text06 {
    text-align: center;
  }
  .home-cta-btn2 {
    width: auto;
    align-self: center;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-text14 {
    text-align: center;
  }
  .home-text15 {
    text-align: center;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card2 {
    width: 70%;
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-text22 {
    text-align: center;
  }
  .home-text23 {
    text-align: center;
  }
  .home-card-content {
    margin: 0px;
  }
  .home-card-content1 {
    margin: 0px;
  }
  .home-card-content2 {
    margin: 0px;
  }
  .home-card-content3 {
    margin: 0px;
  }
  .home-about {
    align-items: center;
  }
  .home-heading-container2 {
    align-items: center;
  }
  .home-text31 {
    text-align: center;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-cta-btn4 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .home-burger-menu {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-logo {
    width: 115px;
  }
  .home-cta-btn {
    font-size: 15px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    display: none;
  }
  .home-image01 {
    width: 41px;
    height: 41px;
  }
  .home-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-image02 {
    width: 100%;
  }
  .home-text14 {
    text-align: center;
  }
  .home-text15 {
    text-align: center;
  }
  .home-feature-card {
    width: 100%;
  }
  .home-feature-card1 {
    width: 100%;
  }
  .home-feature-card2 {
    width: 100%;
  }
  .home-text22 {
    text-align: center;
  }
  .home-text23 {
    text-align: center;
  }
  .home-service-card {
    width: 100%;
  }
  .home-service-card1 {
    width: 100%;
  }
  .home-service-card2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text27 {
    color: var(--dl-color-gray-500);
  }
  .home-service-card3 {
    width: 100%;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-cta-btn4 {
    align-self: center;
  }
}
