:root {
  --dl-color-gray-500: #999999;
  --dl-color-gray-700: #D9D9D9;
  --dl-color-gray-900: #eaeaeaff;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 8px;
  --dl-color-primary-100: #3a26efff;
  --dl-color-primary-500: #8469deff;
  --dl-color-primary-700: #9a91e2ff;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.list-item {
  display: list-item;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Open Sans;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  font-family: Open Sans;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Open Sans;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.Section-Text {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Text {
  font-size: 16px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.BigCard-Heading {
  font-size: 36px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Heading {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Anchor {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  text-transform: capitalize;
  text-decoration: none;
}
.Section-Heading {
  font-size: 48px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
