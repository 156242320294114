.help-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.help-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.help-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.help-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.help-icon {
  width: 36px;
  height: 36px;
}
.help-navlink {
  display: contents;
}
.help-logo {
  width: 125px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.help-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.help-text {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
}
.help-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.help-link {
  display: none;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.help-link1 {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-right: var(--dl-space-space-tripleunit);
  text-transform: capitalize;
  text-decoration: none;
}
.help-link2 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.help-link3 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.help-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.help-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.help-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.help-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.help-image1 {
  width: 50px;
  object-fit: cover;
}
.help-text03 {
  font-size: 18px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
}
.help-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.help-icon2 {
  width: 24px;
  height: 24px;
}
.help-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.help-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.help-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.help-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.help-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.help-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.help-help {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.help-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.help-text06 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.help-text09 {
  color: var(--dl-color-gray-500);
}
.help-text10 {
  font-weight: 700;
}
.help-text13 {
  font-weight: 700;
}
.help-text17 {
  font-weight: 700;
}
.help-text20 {
  font-weight: 700;
}
.help-text24 {
  font-weight: 700;
}
.help-text27 {
  font-weight: 700;
}
.help-text31 {
  font-weight: 700;
}
.help-text34 {
  font-weight: 700;
}
.help-text38 {
  font-weight: 700;
}
.help-text41 {
  font-weight: 700;
}
.help-text45 {
  font-weight: 700;
}
.help-text48 {
  font-weight: 700;
}
.help-text52 {
  font-weight: 700;
}
.help-text55 {
  font-weight: 700;
}
@media(max-width: 767px) {
  .help-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
  .help-icon {
    fill: var(--dl-color-gray-white);
  }
  .help-links-container {
    display: none;
  }
  .help-heading-container {
    width: 100%;
  }
  .help-text06 {
    text-align: center;
  }
  .help-text07 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .help-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .help-mobile-menu {
    display: none;
  }
  .help-image1 {
    width: 41px;
    height: 41px;
  }
  .help-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .help-text06 {
    text-align: center;
  }
  .help-text07 {
    text-align: center;
  }
}
